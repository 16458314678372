import { Image, Text, useSx, View } from "dripsy"
import Layout from "../layout"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useRouter } from "solito/router";

const UserReview = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { push } = useRouter()

    return (
        <View style={sx({
            width: '100%',
            flexDirection: 'column',
            paddingY:[5,10]
        })}>
            <View style={sx({
                width: '100%',
                height: 46,
                marginBottom:[5,10,15,20]
            })}>
                <Text
                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: ['center'],
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            color: '$primary',
                        }
                    )}
                >
                    Real Users, Real Results
                </Text>
            </View>

            <View style={
                sx({
                    display: ['none', 'flex', 'flex'],
                    width: '100%',
                    // height: ('50%')
                    flex: 1,
                })
            }>
                <Image
                    resizeMode={'stretch'}
                    style={{
                        flex: 1,
                        objectFit: 'contain'
                    }}
                    source={require('../../assets/landingPage/Frame.webp')}
                    resizeMethod={'scale'}
                    alt='aboutUs'
                />
            </View>
            <View
                style={sx({
                    width: "100%",
                    justifyContent: 'center',
                    alignItems: ['center']
                })}
            >
                <View style={sx({
                    width: ['80%', '80%', '60%'],
                    marginY: [10, 20, 20]
                })}>
                    <Text style={sx({
                        textAlign: 'center',
                        fontSize: [14,16,18,'24px'],
                        fontWeight: '500',
                        lineHeight: [24,28,32,'36px']
                    })}>
                        Hear from homeowners across the U.S. who have transformed their spaces using Gennoctua’s AI tools. From small apartments to large homes, our users love the simplicity and accuracy we bring to DIY home design.                    </Text>
                </View>

                <Text

                    style={sx(
                        {
                            fontSize: [getFontSize(28, 14), getFontSize(48, 20)],
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            marginBottom: [10, 20, 20]
                        }
                    )}
                >
                    See Success Stories
                </Text>

                <Button
                    style={sx({
                        height: 40,
                        maxWidth: 300,
                        backgroundColor: '#2D1D1D',
                        padding: 10
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                        push('/account/signup')
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Read User Reviews
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>

        </View>

    )
}
export default UserReview;