import { Image, Text, useSx, View } from "dripsy"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useRouter } from "solito/router";

const ExploreOurFeatures = () => {
    const sx = useSx();
    const { push } = useRouter()

    const getFontSize = useCalculateFontSize();
    const list = [
        {
            title: 'Empowerment', icon: require('../../assets/landingPage/protest.webp'),
            desc: 'Snap a picture of your space, and our AI will show you exactly where your furniture fits, making it easy to create the perfect layout without lifting a finger.'
        },
        {
            'title': 'Simplicity', icon: require('../../assets/landingPage/like.webp'), desc: 'Our AI simplifies the most complicated parts of home design, from layout planning to furniture assembly, giving you more time to enjoy your home.'
        },
        {
            'title': 'Customization', icon: require('../../assets/landingPage/edit.webp'), desc: 'No cookie-cutter solutions here. Every space is unique, and our tools adapt to your vision, helping you create a home that reflects your personality.'
        }
    ];

    return (
        <View style={sx({
            width: '100%',
            minHeight: ['50vh','50vh','80vh'],
            flexDirection: 'column',
            paddingX: ['5%','5%','10%'],
            backgroundColor: '#F8FBFA',
           paddingTop:'5%'
        })}>
            <View style={sx({
                width: '100%',
                height: 46,
            })}>
                <Text
                    style={sx(
                        {
                            fontSize: getFontSize(48, 20),
                            fontWeight: '700',
                            textAlign: ['left','right','right'],
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                            color: '$primary',
                        }
                    )}
                >
                    Our Values—What We Bring to You
                </Text>
            </View>
            <View style={sx({
                flexDirection: ['column', 'column', 'row'],
                flex: 1,
                height: 'calc(100% - 46px)',
            })}>

                {list.map((item, index) => {
                    return (
                        <View key={`${index}-index-data`} style={sx({
                            height: [185, 200,300],
                            marginTop:  [0, 0, index === 0 ? 0 : index === 1 ? '10%' : '20%'], 
                            width: ['80%', '100%', '33.33%'],
                            marginLeft: index === 1 ? ['20%', '0', '0'] : 0,
                            border: index === 1 ? ['1px solid #CE7335', '0', '0'] : 0,
                            padding: index === 1 ? [10,0,'5%'] : [0,0,'5%'] ,
                            flexDirection: ['column'],
                            backgroundColor: index % 2 ? '#FFF' : '#F8FBFA'
                        })}>
                            <View style={sx({
                                flexDirection:'row',
                                alignItems:'center'
                            })}>
                            <Image
                                resizeMode={'contain'}
                                style={[sx({ width: ['30px','40px'], height: ['30px','40px'], objectFit: 'contain' })]}
                                source={item.icon}
                                resizeMethod={'scale'}
                                alt='aboutUs'
                            />
                            <Text style={sx({
                                fontSize: getFontSize(38, 15),
                                fontWeight: '700',
                                textAlign: 'left',
                                lineHeight: '46px',
                                letterSpacing: '-0.02em',
                                color: '#030605',
                                fontStyle: 'italic',
                                marginLeft: 5,
                                paddingTop: -5,
                            })}>{item.title}</Text>
                            </View>
                            <Text style={sx({
                                fontSize: getFontSize(24, 12),
                                fontWeight: '700',
                                textAlign: 'left',
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                                color: '#5C5E5E',
                            })}>
                                {item.desc}
                            </Text>
                        </View>
                    )
                })}
            </View>
            <View
                style={sx({
                    width: "100%",
                    justifyContent: 'center',
                    alignItems:['flex-start','flex-start' ,'center']
                })}
            >
                <Text

                    style={sx(
                        {
                            fontSize: [getFontSize(28, 14),getFontSize(48, 20)],
                            fontWeight: '700',
                            textAlign: 'left',
                            lineHeight: '46px',
                            letterSpacing: '-0.02em',
                        }
                    )}
                >
                   Discover How Easy DIY Can Be
                </Text>
                <View style={sx({
                    height:[10,20]
                })}></View>
                <Button
                    style={sx({
                        height: 40,
                        maxWidth: 300,
                        backgroundColor: '#2D1D1D',
                        padding: 10
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                        push('/account/signup')
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Explore Our Features
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>
        </View>

    )
}
export default ExploreOurFeatures;