import { Image, Text, useSx, View } from "dripsy"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useRouter } from "solito/router";



const ContactUs = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { push } = useRouter()

    return (
        <View style={sx({
            flexDirection: 'row',
            width: '100%',
            backgroundColor: '$primary',
            height: ['50vh','80vh'],
            paddingLeft:'10%'
        })}>
            <View style={
                sx({
                    flex:1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                })
            }>
                <Text style={
                    sx({
                        fontSize: getFontSize(48, 20),
                        fontWeight: '700',
                        textAlign: 'left',
                        lineHeight:[24,35, '54.24px'],
                        letterSpacing: ['-0.02em'],
                        color: '#FFF'
                    })
                }>Turn Your Home Design Vision into Reality with AI-Powered DIY Tools</Text>
                <View style={sx({ height: [20,40] })} />
                <Text style={
                    sx({
                        fontSize: getFontSize(24, 10),
                        fontWeight: '500',
                        textAlign: 'left',
                        color: '#FFF',
                        lineHeight:['20px', '34px'],
                    })
                }>Visualize, Design, and Build Your Dream Space—No Expertise Needed.</Text>
                <View style={sx({ height: [20,40] })} />
                <Button
                    style={sx({
                        height: [30,40],
                        maxWidth: 200,
                        backgroundColor: '#2D1D1D',
                        padding: [5,10]
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                        push('/support')
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Contact Us
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>

            <View style={
                sx({
                    flex: [1,2],
                    flexDirection: 'row',
                    justifyContent:'flex-end',
                })
            }>
                <Image
                    resizeMode={'contain'}
                    style={[sx({ width: ['70%'], height: ['100%'] , minHeight:200, minWidth:150 ,  objectFit: 'contain' })]}
                    source={require('../../assets/homePageLogo.webp')}
                    resizeMethod={'scale'}
                    alt='homePageLogo'
                    
                />
            </View>
        </View>

    )
}

export default ContactUs;
