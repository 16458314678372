import { Image, Text, TextInput, useSx, View } from "dripsy"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useRouter } from "solito/router";

const OurBlog = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { push } = useRouter()

    return (
        <View style={sx({
            width: '100%',
            height: '100%',
            flexDirection: 'column',
        })}>
            <View style={sx({
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                padding: ['5%', 0, 0],
                paddingRight: [0, '5%', '5%'],
                marginTop: [0, 10, 10, 20]
            })}>
                <View style={sx({
                    width: '100%',
                    height: 46,
                    marginBottom: [5, 10, 15, 20],
                })}>
                    <Text
                        style={sx(
                            {
                                fontSize: getFontSize(48, 20),
                                fontWeight: '700',
                                textAlign: ['center', 'right', 'right'],
                                lineHeight: '46px',
                                letterSpacing: '-0.02em',
                                color: '$primary',
                            }
                        )}
                    >
                        Join the DIY Revolution
                    </Text>
                </View>

                <View style={
                    sx({
                        width: ['100%'],
                        flex: 1,
                        backgroundColor: 'red',
                        position: 'relative'
                    })
                }>
                    <Image
                        resizeMode={'stretch'}
                        style={sx({
                            flex: 1,
                            minHeight: [400, 500],
                            objectFit: 'contain'
                        })}
                        source={require('../../assets/landingPage/image.webp')}
                        resizeMethod={'scale'}
                        alt='aboutUs'
                    />
                    <View
                        style={sx({
                            width: '60%',
                            height: '80%',
                            borderRadius: '10px 0px 0px 0px',
                            position: 'absolute',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            top: '50%',
                            left: '50%',
                            padding: '20px',
                            transform:
                                'translate(-50%, -50%)',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: 250
                        })}>
                        <Text style={
                            sx({
                                color: '#FFF',
                                textAlign: 'center',
                                fontSize: getFontSize(20, 14),
                                fontWeight: '500',
                                lineHeight: ['20px', '24px', '28px'],
                            })
                        }>
                            Ready to bring your home design ideas to life? With Gennoctua’s AI-driven tools, you can confidently take on any project, big or small. It’s time to create a space that feels uniquely yours.
                        </Text>
                        <View style={{
                            height: 20
                        }} />
                        <Text style={
                            sx({
                                color: '#FFF',
                                textAlign: 'center',
                                fontSize: getFontSize(32, 20),
                                fontWeight: '500',
                                lineHeight: ['20px', '24px', '28px'],
                            })
                        }>
                            Start Your Project Now
                        </Text>
                        <Button
                            style={sx({
                                height: 35,
                                backgroundColor: '$primary',
                                marginTop: 10
                            })}
                            textStyle={{
                                fontSize: 14,
                            }}
                            onPress={() => {
                        push('/account/signup')
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <Text style={sx({
                                    color: '#FFF',
                                    marginRight: [10, 10, 20],
                                    fontSize: getFontSize(28, 10),
                                })}>
                                    Create Your Free Account
                                </Text>
                                <Icon name={'arrow'} fill={['#FFF']} style={{
                                    transform: [{ rotate: '90deg' }],
                                }} />
                            </View>
                        </Button>

                    </View>
                </View>
                <View style={sx({
                    marginTop: 20,
                        paddingX: [20,'10%'],
                })} >
                    <View style={sx({
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    })}>
                        <Text
                            style={sx({
                                // fontFamily: Roxborough CF;
                                fontSize: [getFontSize(40, 14), getFontSize(64, 20)],
                                fontWeight: '700',
                                lineHeight: '74.24px',
                                textAlign: 'left',
                                color: '$primary',
                            })}
                        >
                            Explore Our Expert Blog
                        </Text>
                        <Image
                            resizeMode={'stretch'}
                            style={sx({
                                height: [60, 100],
                                width: [60, 100],
                                objectFit: 'contain'
                            })}
                            source={require('../../assets/landingPage/vector.png')}
                            resizeMethod={'scale'}
                            alt='aboutUs'
                        />
                    </View>


                </View>

                <View
                    style={sx({
                        width: "100%",
                        // justifyContent: 'center',
                        // alignItems: ['flex-start'],
                        paddingLeft: [20,'10%'],
                    })}
                >
                    <View style={sx({
                        width: ['100%'],
                        marginY: [10, 20, 20]
                    })}>
                        <Text style={sx({
                            textAlign: 'left',
                            fontSize: [14, 16, 18, 24],
                            fontWeight: '500',
                            lineHeight: [24, 28, 32, 36]
                        })}>
                            Dive deeper into the world of DIY home improvement, innovative design trends, and the latest in AI-powered tools. Our blog is filled with expert tips, inspiring ideas, and step-by-step guides to help you transform your space with confidence.
                            Whether you're a seasoned DIYer or just getting started, there's something for everyone.
                        </Text>    </View>

                    <Text

                        style={sx(
                            {
                                fontSize: [getFontSize(28, 14), getFontSize(48, 20)],
                                fontWeight: '700',
                                textAlign: 'left',
                                lineHeight: '46px',
                                letterSpacing: '-0.02em',
                                marginBottom: [10, 20, 20]
                            }
                        )}
                    >
                        Explore Our Blog
                    </Text>

                    <Button
                        style={sx({
                            height: 40,
                            maxWidth: 300,
                            backgroundColor: '#2D1D1D',
                        })}
                        textStyle={{
                            fontSize: 14,
                        }}
                        onPress={() => {
                            push('/blog')
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Text style={sx({
                                color: '#FFF',
                                marginRight: 20,
                                fontSize: getFontSize(28, 14),
                            })}>
                                Get Inspired Now
                            </Text>
                            <Icon name={'arrow'} fill={['#FFF']} style={{
                                transform: [{ rotate: '90deg' }],
                            }} />
                        </View>
                    </Button>
                </View>
            </View>
            <ContactForm />
        </View>
    )
}
export default OurBlog;


const ContactForm = () => {
    const sx = useSx();
    return (
        <View style={sx({
            minHeight: 400,
            width: '100%',
            minWidth: 200,
            marginTop: 20
        })}>
            <View style={sx({
                flexDirection: 'row',
                backgroundColor: '$primary',
                height: '100%',
                width: '100%',
                minHeight: 400,
                borderBottomRightRadius: [0, 0, '250px'],
                justifyContent: 'center',
                alignItems: 'center'
            })}>
                <View style={sx({
                    flex: 1,
                    padding: 20,
                    justifyContent: 'center',
                    paddingRight: [0, '5%', '10%']
                })}>
                    <Text style={{
                        fontSize: 24,
                        fontWeight: 'bold',
                        color: 'white',
                        marginBottom: 10,
                    }}>Interest in Working with Us?</Text>
                    <Text style={sx({
                        fontSize: 18,
                        color: 'white',
                        marginBottom: 20,
                    })}>Get In Touch</Text>
                    <View style={{
                        flexDirection: 'row',
                        backgroundColor: 'white',
                        borderRadius: 5,
                        overflow: 'hidden',
                    }}>
                        <TextInput
                            style={[sx({
                                flex: 1,
                                padding: 10,
                                fontSize: 16,
                            }), Platform.OS === 'web'
                                ? sx({
                                    outline: 'none',
                                }) : {}]}
                            placeholder="Your email address"
                            placeholderTextColor="#999"
                        />
                        <TouchableOpacity style={sx({
                            backgroundColor: '#333',
                            paddingHorizontal: 15,
                            justifyContent: 'center',
                            height: '100%'
                        })}>
                            <Text style={sx({
                                color: 'white',
                                fontWeight: 'bold',
                            })}>Let's Chat</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={sx({
                    width: '30%',
                    display: ['none', 'flex', 'flex']
                })}>
                    <Image
                        resizeMode={'cover'}
                        style={sx({
                            height: ['350px'],
                            width: [200],
                            objectFit: 'contain'
                        })}
                        source={require('../../assets/landingPage/image_1.webp')}
                        resizeMethod={'scale'}
                        alt='aboutUs'
                    />

                </View>
            </View>
        </View>
    );
};