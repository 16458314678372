import { Image, Text, useSx, View } from "dripsy"
import { useCalculateFontSize } from "app/redux/hooks/use-calculate-font-size";
import { Button } from "app/components/button";
import { Icon } from "app/components/icon/icon";
import { useRouter } from "solito/router";

const Aboutus = () => {
    const sx = useSx();
    const getFontSize = useCalculateFontSize();
    const { push } = useRouter()

    return (
        <View style={sx({
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            padding: [0,'5%'],
            backgroundColor: '#F8FBFA',

})}>
            <View style={
                sx({
                    flex: 1,
                    display: ['none', 'flex'],
                })
            }>
                <Image
                    resizeMode={'contain'}
                    style={[sx({ minHeight: [300, 400, 500], width: ['100%'], height: ['100%'] })]}
                    source={require('../../assets/aboutUs.webp')}
                    resizeMethod={'scale'}
                    alt='aboutUs'
                />
            </View>

            <View style={
                sx({
                    flex: 3,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '5%',
                })
            }>
                <Text style={sx(
                    {
                        fontSize: getFontSize(48, 20),
                        fontWeight: '700',
                        textAlign: 'left',
                        lineHeight: '46px',
                        letterSpacing: '-0.02em',
                        color: '$primary',
                    }
                )}>
                    About Us
                </Text>
                <View style={sx({ height: [0,20] })} />
                <Text style={
                    sx({
                        fontSize: [getFontSize(28, 10), getFontSize(38, 14), getFontSize(48, 20)],
                        fontWeight: '500',
                        textAlign: 'left',
                        lineHeight: [24, 28, '34.24px'],
                        letterSpacing: '-0.02em',
                    })
                }>
                    Home improvement should be exciting, not overwhelming.
                    At Gennoctua, we make DIY home design effortless with cutting-edge AI tools that help you plan, visualize, and execute your projects with ease.
                    Whether you’re arranging furniture or assembling a complex setup, we guide you every step of the way.
                </Text>
                <View style={sx({ height: [10,20] })} />
                <Button
                    style={sx({
                        height: 40,
                        maxWidth: [250,300],
                        backgroundColor: '#2D1D1D',
                        padding: 10
                    })}
                    textStyle={{
                        fontSize: 14,
                    }}
                    onPress={() => {
                        push('/account/signup')
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Text style={{
                            color: '#FFF',
                            marginRight: 20
                        }}>
                            Start Your DIY Journey Today
                        </Text>
                        <Icon name={'arrow'} fill={['#FFF']} style={{
                            transform: [{ rotate: '90deg' }],
                        }} />
                    </View>
                </Button>
            </View>


        </View>

    )
}

export default Aboutus;
